import { Paper, Table, TableBody, TableRow,
         TableContainer, TableHead } from '@mui/material';
import {InformesTableCell, InformesTableRow} from '../../../components/CustomStyles';
import { DARK_GREY, GREY } from '../../../theme';
import { styled } from '@mui/material/styles';
import { useResponsive } from '../../../hooks/useResponsive';
import { reportsCreditRiskService } from '../../../services/reportsCreditRiskService';
import { useEffect, useState } from 'react';
import InLineError from '../../../components/InLineError';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { useErrors } from '../../../hooks/useErrors';
import useNumberFormatter from '../../../hooks/useNumberFormatter';


const StyledResultTableRow = styled(TableRow)(({ theme }) => ({
    '&.MuiTableRow-body': {
        border: 'solid 2px' + DARK_GREY,
    },
    '& .MuiTableCell-root': {
        backgroundColor: GREY
    },
}));


const NormalRow = ({ row }) => {
    const formatNumber = useNumberFormatter();
    return (
        <>
            {/* Main table entry */}
            <InformesTableRow >
                <InformesTableCell>{row.dt_mes}</InformesTableCell>
                <InformesTableCell>{row.Epigrafe}</InformesTableCell>
                <InformesTableCell>{row.id_categoria_exposicion} - {row.category_name}</InformesTableCell>
                <InformesTableCell sx={{ textAlign: 'right' }}>{formatNumber(row.pc_ponderacion)}</InformesTableCell>
                <InformesTableCell sx={{ textAlign: 'right' }}>{formatNumber(row.Expo_original)}</InformesTableCell>
                <InformesTableCell sx={{ textAlign: 'right' }}>{formatNumber(row.APRs)}</InformesTableCell>
                <InformesTableCell sx={{ textAlign: 'right' }}>{formatNumber(row.Cobertura)}</InformesTableCell>
                <InformesTableCell sx={{ textAlign: 'right' }}>{formatNumber(row.Gar_reales)}</InformesTableCell>
                <InformesTableCell sx={{ textAlign: 'right' }}>{formatNumber(row.Gar_personales)}</InformesTableCell>
                <InformesTableCell sx={{ textAlign: 'right' }}>{formatNumber(row.Gar_hipotecarias)}</InformesTableCell>
                <InformesTableCell sx={{ textAlign: 'right' }}>{formatNumber(row.Gar_ponderadas)}</InformesTableCell>
            </InformesTableRow>
        </>
    );
}


const TotalRow = ({ row }) => {
    const formatNumber = useNumberFormatter();
    return (
        <>
            {/* Main table entry */}
            <StyledResultTableRow>
                <InformesTableCell><b>{row.dt_mes}</b></InformesTableCell>
                <InformesTableCell colSpan={3}><b>{row.Epigrafe}</b></InformesTableCell>
                <InformesTableCell sx={{ textAlign: 'right' }}><b>{formatNumber(row.Expo_original)}</b></InformesTableCell>
                <InformesTableCell sx={{ textAlign: 'right' }}><b>{formatNumber(row.APRs)}</b></InformesTableCell>
                <InformesTableCell sx={{ textAlign: 'right' }}><b>{formatNumber(row.Cobertura)}</b></InformesTableCell>
                <InformesTableCell sx={{ textAlign: 'right' }}><b>{formatNumber(row.Gar_reales)}</b></InformesTableCell>
                <InformesTableCell sx={{ textAlign: 'right' }}><b>{formatNumber(row.Gar_personales)}</b></InformesTableCell>
                <InformesTableCell sx={{ textAlign: 'right' }}><b>{formatNumber(row.Gar_hipotecarias)}</b></InformesTableCell>
                <InformesTableCell sx={{ textAlign: 'right' }}><b>{formatNumber(row.Gar_ponderadas)}</b></InformesTableCell>
            </StyledResultTableRow>
        </>
    );
}


export default function SummaryTable() {
    const { isBelowXlScreen } = useResponsive();
    const { loading, inLineErrors } = useErrors();
    const { getCreditRiskReportSummary } = reportsCreditRiskService();
    const [reportData, setReportData] = useState([]);
    const [rowsDerivados, setRowsDerivados] = useState([]);
    const [rowTotalDerivados, setRowTotalDerivados] = useState([]);
    const [rowsInversionCrediticia, setRowsInversionCrediticia] = useState([]);
    const [rowTotalInversionCrediticia, setRowTotalInversionCrediticia] = useState([]);
    const [rowsResto, setRowsResto] = useState([]);
    const [rowTotalResto, setRowTotalResto] = useState([]);
    const [rowsRentaFija, setRowsRentaFija] = useState([]);
    const [rowTotalRentaFija, setRowTotalRentaFija] = useState([]);
    const [rowsRentaVariable, setRowsRentaVariable] = useState([]);
    const [rowTotalRentaVariable, setRowTotalRentaVariable] = useState([]);

    useEffect(() => {
        fetchReportData();
    }, []);

    const fetchReportData = async () => {
        const reportData = await getCreditRiskReportSummary({ year: 2024, month: "07", process_id: "dcaab6cc-7b97-4b64-8506-fd2b2f962ba9" });
        const sortedReportData = reportData.sort((a, b) => {
            return a.id_categoria_exposicion.localeCompare(b.id_categoria_exposicion);
        });
        setReportData(sortedReportData);
        if (reportData) {
            const [derivados, inversionCrediticia, resto, rentaFija, rentaVariable] = separateDataByEpigraph(sortedReportData);
            setRowsDerivados(derivados);
            setRowTotalDerivados(calculateTotal(derivados));
            setRowsInversionCrediticia(inversionCrediticia);
            setRowTotalInversionCrediticia(calculateTotal(inversionCrediticia));
            setRowsResto(resto);
            setRowTotalResto(calculateTotal(resto));
            setRowsRentaFija(rentaFija);
            setRowTotalRentaFija(calculateTotal(rentaFija));
            setRowsRentaVariable(rentaVariable);
            setRowTotalRentaVariable(calculateTotal(rentaVariable));
        }
    }

    const separateDataByEpigraph = (data) => {
        const dataDerivados = data.filter(item => item.Epigrafe === 'Derivados');
        const dataInversionCrediticia = data.filter(item => item.Epigrafe === 'Inversión Crediticia');
        const dataResto = data.filter(item => item.Epigrafe === 'Resto');
        const dataRentaFija = data.filter(item => item.Epigrafe === 'Renta Fija');
        const dataRentaVariable = data.filter(item => item.Epigrafe === 'Renta variable');

        return [dataDerivados, dataInversionCrediticia, dataResto, dataRentaFija, dataRentaVariable];
    }

    const calculateTotal = (data) => {
        const total = data.reduce((acc, curr) => {
            acc.Expo_original += curr.Expo_original;
            acc.Cobertura += curr.Cobertura;
            acc.APRs += curr.APRs;
            acc.Gar_ponderadas += curr.Gar_ponderadas;
            acc.Gar_reales += curr.Gar_reales;
            acc.Gar_personales += curr.Gar_personales;
            acc.Gar_hipotecarias += curr.Gar_hipotecarias;
            return acc;
          }, {
            dt_mes: data[0]?.dt_mes || '',
            Epigrafe: data[0]?.Epigrafe + ' TOTAL',
            Expo_original: 0,
            Cobertura: 0,
            APRs: 0,
            Gar_ponderadas: 0,
            Gar_reales: 0,
            Gar_personales: 0,
            Gar_hipotecarias: 0
          });

        return total;
    }

    if (loading.getCreditRiskReportSummary) {
        return (
            <LoadingSpinner/>
        )
    }
    else if (inLineErrors.getCreditRiskReportSummary) {
        return (
            <InLineError width={"100%"} length={"100%"} errorMessage={inLineErrors.getCreditRiskReportSummary} />
        )
    }
    else {
        return (
            <TableContainer
                component={Paper}
                sx={{ boxShadow: 'none', mt: '7px', overflowX: isBelowXlScreen ? 'auto' : 'hidden'}}
            >
                <Table size='small' sx={{ borderCollapse: 'collapse', minWidth: '1250px' }} >
                    <TableHead>
                        <InformesTableRow>
                            <InformesTableCell sx={{ textAlign: 'left' }} width={"6%"}>Fecha de datos</InformesTableCell>
                            <InformesTableCell sx={{ textAlign: 'left' }} width={"8%"}>Epígrafe</InformesTableCell>
                            <InformesTableCell sx={{ textAlign: 'left' }} width={"10%"}>Categoría</InformesTableCell>
                            <InformesTableCell sx={{ textAlign: 'right' }} width={"4%"}>Ponderación</InformesTableCell>
                            <InformesTableCell sx={{ textAlign: 'right' }} width={"10%"}>Exposición Original</InformesTableCell>
                            <InformesTableCell sx={{ textAlign: 'right' }} width={"10%"}>APRS</InformesTableCell>
                            <InformesTableCell sx={{ textAlign: 'right' }} width={"8%"}>Cobertura</InformesTableCell>
                            <InformesTableCell sx={{ textAlign: 'right' }} width={"6%"}>Garantías Reales</InformesTableCell>
                            <InformesTableCell sx={{ textAlign: 'right' }} width={"6%"}>Garantías Personales</InformesTableCell>
                            <InformesTableCell sx={{ textAlign: 'right' }} width={"6%"}>Garantías Hipotecarias</InformesTableCell>
                            <InformesTableCell sx={{ textAlign: 'right' }} width={"6%"}>Garantías Ponderadas</InformesTableCell>
                        </InformesTableRow>
                    </TableHead>
                    {reportData.length === 0 ?
                        <TableBody>
                            <InformesTableRow>
                                <InformesTableCell colSpan={11} sx={{ textAlign: 'center' }}>No hay datos para mostrar</InformesTableCell>
                            </InformesTableRow>
                        </TableBody>
                        : 
                        <TableBody>
                            {rowsDerivados.map((row, index) => (
                                <NormalRow key={index} row={row} />
                            ))}
                            <TotalRow row={rowTotalDerivados} />
                            {rowsInversionCrediticia.map((row, index) => (
                                <NormalRow key={index} row={row} />
                            ))}
                            <TotalRow row={rowTotalInversionCrediticia} />
                            {rowsRentaFija.map((row, index) => (
                                <NormalRow key={index} row={row} />
                            ))}
                            <TotalRow row={rowTotalRentaFija} />
                            {rowsRentaVariable.map((row, index) => (
                                <NormalRow key={index} row={row} />
                            ))}
                            <TotalRow row={rowTotalRentaVariable} />
                            {rowsResto.map((row, index) => (
                                <NormalRow key={index} row={row} />
                            ))}
                            <TotalRow row={rowTotalResto} />
                        </TableBody>
                    }
                    
                </Table>
            </TableContainer>
        );
    }
}