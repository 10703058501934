import React, { useState, useEffect } from 'react';
import { Paper, Table, TableBody,
    TableContainer, TableHead } from '@mui/material';
import { InformesTableCell, InformesTableRow } from '../../../components/CustomStyles';
import { useResponsive } from '../../../hooks/useResponsive';
import { reportsCreditRiskService } from '../../../services/reportsCreditRiskService'
import { useErrors } from '../../../hooks/useErrors';
import InLineError from '../../../components/InLineError';
import LoadingSpinner from '../../../components/LoadingSpinner';
import useNumberFormatter from '../../../hooks/useNumberFormatter';


const Row = ({ row }) => {
    const formatNumber = useNumberFormatter();
    return (
        <>
            {/* Main table entry */}
            <InformesTableRow >
                <InformesTableCell>{row.dt_mes}</InformesTableCell>
                <InformesTableCell>{row.id_entidad_contable} - {row.na_entidad}</InformesTableCell>
                <InformesTableCell>{row.Epigrafe}</InformesTableCell>
                <InformesTableCell>{row.id_categoria_exposicion} - {row.category_name}</InformesTableCell>
                <InformesTableCell sx={{ textAlign: 'right' }}>{formatNumber(row.pc_ponderacion)}</InformesTableCell>
                <InformesTableCell sx={{ textAlign: 'right' }}>{formatNumber(row.Expo_original)}</InformesTableCell>
                <InformesTableCell sx={{ textAlign: 'right' }}>{formatNumber(row.Cobertura)}</InformesTableCell>
                <InformesTableCell sx={{ textAlign: 'right' }}>{formatNumber(row.APRs)}</InformesTableCell>
            </InformesTableRow>
        </>
    );
}


export default function SummaryByEntityTable() {
    const { isBelowLgScreen } = useResponsive();
    const { loading, inLineErrors } = useErrors();
    const { getCreditRiskReportByEntity } = reportsCreditRiskService();
    const [reportData, setReportData] = useState([]);

    useEffect(() => {
        fetchReportData();
    }, []);

    const fetchReportData = async () => {
        const reportData = await getCreditRiskReportByEntity({ year: 2024, month: "07", process_id: "dcaab6cc-7b97-4b64-8506-fd2b2f962ba9" });
        const sortedReportData = reportData.sort((a, b) => {
            // Primero ordenar por id_entidad_contable (numérico)
            if (a.id_entidad_contable < b.id_entidad_contable) return -1;
            if (a.id_entidad_contable > b.id_entidad_contable) return 1;
        
            // Si los id_entidad_contable son iguales, ordenar por Epigrafe (alfabético)
            if (a.Epigrafe < b.Epigrafe) return -1;
            if (a.Epigrafe > b.Epigrafe) return 1;
        
            // Si los Epigrafe son iguales, ordenar por id_categoria_exposicion (numérico)
            if (a.id_categoria_exposicion < b.id_categoria_exposicion) return -1;
            if (a.id_categoria_exposicion > b.id_categoria_exposicion) return 1;
        
            return 0; // Si todos son iguales
        });
        setReportData(sortedReportData);
    }

    if (loading.getCreditRiskReportByEntity) {
        return (
            <LoadingSpinner/>
        )
    }
    else if (inLineErrors.getCreditRiskReportByEntity) {
        return (
            <InLineError width={"100%"} length={"100%"} errorMessage={inLineErrors.getCreditRiskReportByEntity} />
        )
    }
    else {
        return (
            <TableContainer
                component={Paper}
                sx={{ boxShadow: 'none', mt: '7px', overflowX: isBelowLgScreen ? 'auto' : 'hidden' }}
            >
                <Table size='small' sx={{ borderCollapse: 'collapse', minWidth: '1250px' }} >
                    <TableHead>
                        <InformesTableRow>
                            <InformesTableCell sx={{ textAlign: 'left' }} width={"10%"}>
                                Fecha de datos 
                            </InformesTableCell>
                            <InformesTableCell sx={{ textAlign: 'left' }} width={"14%"}>
                                Entidad
                            </InformesTableCell>
                            <InformesTableCell sx={{ textAlign: 'left' }} width={"14%"}>
                                Epígrafe
                            </InformesTableCell>
                            <InformesTableCell sx={{ textAlign: 'left' }} width={"14%"}>
                                Categoría
                            </InformesTableCell>
                            <InformesTableCell sx={{ textAlign: 'right' }} width={"10%"}>
                                Ponderación
                            </InformesTableCell>
                            <InformesTableCell sx={{ textAlign: 'right' }} width={"12%"}>
                                Exposición Original
                            </InformesTableCell>
                            <InformesTableCell sx={{ textAlign: 'right' }} width={"12%"}>
                                Cobertura
                            </InformesTableCell>
                            <InformesTableCell sx={{ textAlign: 'right' }} width={"12%"}>
                                APRS
                            </InformesTableCell>
                        </InformesTableRow>
                    </TableHead>
                    {reportData.length === 0 ?
                            <TableBody>
                                <InformesTableRow>
                                    <InformesTableCell colSpan={8} sx={{ textAlign: 'center' }}>No hay datos para mostrar</InformesTableCell>
                                </InformesTableRow>
                            </TableBody>
                            : 
                            <TableBody>
                                {reportData.map((row, index) => (
                                    <Row key={index} row={row} />
                                ))}
                            </TableBody>
                        }
                </Table>
            </TableContainer>
        );
    }
}