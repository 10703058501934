import React from 'react';
import ReactApexChart from 'react-apexcharts';

const BarLineChart = ({ categories, series }) => {
    // Separar las series de tipo bar y line para configurar los ejes y opciones adecuadamente
    const barSeries = series.filter(s => s.type === 'bar');
    const lineSeries = series.filter(s => s.type === 'line');

    const options = {
        chart: {
            type: 'line',
            stacked: false
        },
        plotOptions: {
            bar: {
                columnWidth: '50%'
            }
        },
        stroke: {
            width: series.map(s => s.type === 'line' ? 4 : 0)
        },
        xaxis: {
            categories: categories,
            labels: {
                rotate: -30,
                style: {
                    fontSize: '12px'
                }
            }
        },
        yaxis: [
            {
                title: {
                    text: 'Value'
                },
                axisTicks: {
                    show: true
                },
                axisBorder: {
                    show: true
                }
            }
        ],
        tooltip: {
            shared: true,
            intersect: false
        },
        legend: {
            horizontalAlign: 'center',
            position: 'top',
            offsetX: 40
        },
        grid: {
            padding: {
                bottom: 30 // Aumenta este valor para dar más espacio a las etiquetas del eje x
            }
        }
    };

    return (
        <div id="chart">
            <ReactApexChart options={options} series={series} type="line" height={400} />
        </div>
    );
};

export default BarLineChart;
